
import { Component, Vue, Watch } from "vue-property-decorator";

import i18n from "@/locales/index";
import { namespace } from "vuex-class";

const AuthModule = namespace("auth");

@Component({
  components: {},
})
export default class ChangeLocale extends Vue {
  public name = "ChangeLocale";

  @AuthModule.Getter("getLanguage")
  protected getLanguage: any;

  @AuthModule.Mutation("SET_LANGUAGE")
  protected setLanguage: any;

  private get locale(): any {
    return i18n.locale;
  }

  @Watch("locale", { immediate: true, deep: true })
  private onLocaleChange(newVal: any, oldVal: any) {
    this.$nextTick(() => {
      if (oldVal === undefined) {
        this.changeLocal(this.getLanguage);
      } else {
        this.changeLocal(newVal);
      }
    });
  }

  private changeLocal(locale: any) {
    i18n.locale = locale;
    this.setLanguage(locale);
    this.$root.$moment.locale(locale === "en" ? "en-gb" : locale);
  }
}
