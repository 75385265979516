
import { Component, Vue } from "vue-property-decorator";
import Navbar from "@/components/navbar/Navbar.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";

@Component({
  components: { Breadcrumbs, Navbar },
})
export default class DefaultLayout extends Vue {
  protected name = "DefaultLayout";
}
