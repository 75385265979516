import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ICustomer from "@/interfaces/ICustomer";

const AppModule = namespace("app");

@Component({})
export class AppStoreMixin extends Vue {
  /**
   * Getters
   */
  @AppModule.Getter("getCurrentCustomer")
  protected getCurrentCustomer!: ICustomer | null;

  /**
   * Mutations
   */
  @AppModule.Mutation("SET_CURRENT_CUSTOMER")
  protected setCurrentCustomer: any;
}
