
import { Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AppConfig from "@/config/AppConfig";
import ChangeLocale from "@/components/change_locale/ChangeLocale.vue";
import { mixins } from "vue-class-component";
import { BaseDataCustomerStoreMixin } from "@/mixins/Stores/BaseData/CustomerStoreMixin";
import { BaseDataCustomer } from "@/config/Modules";
import ICustomer from "@/interfaces/ICustomer";
import { AppStoreMixin } from "@/mixins/Stores/AppStoreMixin";
import { getIsListLoading } from "@/store/crud/functions/getters";
import Str from "../../helper/Str";

const AuthModule = namespace(AppConfig.AUTH_STORE);

@Component({
  computed: {
    Str() {
      return Str;
    },
  },
  methods: { getIsListLoading },
  components: { ChangeLocale },
})
export default class Navbar extends mixins(
  AppStoreMixin,
  BaseDataCustomerStoreMixin
) {
  protected name = "Navbar";

  @AuthModule.Action("logout")
  protected logoutAction: any;

  protected selectedCustomer: any = null;

  /*
  protected get customers(): Array<ICustomer> {
    return this.getBaseDataCustomerList;
  }
   */
  protected customers: Array<ICustomer> = [];

  protected get currentCustomer(): ICustomer | null {
    return this.getCurrentCustomer;
  }

  @Watch("currentCustomer", { immediate: true, deep: true })
  protected onCurrentCustomerChanged(customer: ICustomer | null): void {
    if (customer) {
      this.selectedCustomer = customer.customer;
    }
  }
  @Watch("getBaseDataCustomerList", { immediate: true, deep: true })
  protected onBaseDataCustomerListChanged(customers: Array<ICustomer>): void {
    this.customers = customers;
  }

  protected onCustomerSelect(customer: ICustomer): void {
    this.$router.push({
      name: "CustomerDashboard",
      params: {
        id: String(customer.id),
      },
    });
  }

  protected registerEchoListeners(): void {
    this.$echo
      .channel("Customer")
      .listen(".CustomerUpdated", (e: any) => {
        this.loadCustomers();
      })
      .listen(".CustomerCreated", (e: any) => {
        this.loadCustomers();
      })
      .listen(".CustomerDeleted", (e: any) => {
        console.debug("Customer deleted event ..", e);
        this.loadCustomers();
      });
  }

  protected loadCustomers(): void {
    /*
    this.$apiService.get(BaseDataCustomer.resource).then((response: any) => {
      if (response?.data?.data) {
        console.debug("customers (navbar)", response.data);
        this.customers = response.data.data;
      }
    });
    
     */
    this.findAllBaseDataCustomerItemsAction({
      resource: BaseDataCustomer.resource,
    });
  }

  created() {
    this.registerEchoListeners();
    this.loadCustomers();
  }
}
