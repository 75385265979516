
import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class Breadcrumbs extends Vue {
  public name = "Breadcrumbs";

  private breadcrumbs: Array<any> = [];

  @Watch("$route", { immediate: true, deep: true })
  private onRouteChange(newVal: any): void {
    this.breadcrumbs =
      this.$route && this.$route.meta ? this.$route.meta.breadcrumbs : [];
  }

  private isActiveRoute(routeName: string): boolean {
    return routeName === this.$route.name;
  }

  protected get hideBreadcrumbs(): boolean {
    return this.$route?.meta?.breadcrumbs?.hidden;
  }

  private breadcrumbTitle(breadcrumb: any, params: any) {
    if (params) {
      // find title which need translation
      let regex = /trans\(([a-z\-_.]*)\)/gm;
      let matches = regex.exec(breadcrumb.title);

      if (matches) {
        const param = matches[1];

        return this.$tc(param, 2);
      }

      // find dynamic titles
      regex = /:([a-z-]*)$/gm;
      matches = regex.exec(breadcrumb.title);

      if (matches) {
        const param = matches[1];

        return this.makeTitle(params[param]);
      }
    }

    return breadcrumb.title;
  }

  private breadcrumbTitleFromVuex(breadcrumb: any, params: any): string {
    let getterUri = String(
      breadcrumb.vuex.module + "/" + breadcrumb.vuex.getter
    );

    if (
      this.$store.getters[getterUri] &&
      this.$store.getters[getterUri][breadcrumb.vuex.column] &&
      this.$store.getters[getterUri][breadcrumb.vuex.column]
    ) {
      return String(this.$store.getters[getterUri][breadcrumb.vuex.column]);
    }

    return "";
  }

  private makeTitle(slug: string): string {
    const words = slug.split("-");

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    return words.join(" ");
  }

  mounted() {}
}
